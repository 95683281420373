import * as React from 'react';
import {
    Button,
    View,
    Text,
    ImageBackground,
    ScrollView,
    StyleSheet,
    SafeAreaView,
    Image,
    Pressable, Platform,
    Dimensions, Picker, TouchableOpacity
} from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useState, useEffect } from 'react';

import {
    useFonts,
    HindSiliguri_300Light,
    HindSiliguri_400Regular,
    HindSiliguri_500Medium,
    HindSiliguri_600SemiBold,
    HindSiliguri_700Bold,
} from '@expo-google-fonts/hind-siliguri';
const screenWidth = Dimensions.get('screen').width;
const screenHeight = Dimensions.get('screen').height;
import moment from "moment";

import 'moment/locale/bn';




moment.locale('bn');
const currentDate = moment().format('LL');

function QurbaniScreen({ navigation }) {
    const [showInfo, setShowInfo] = useState(false);

    const handlePress = () => {
        setShowInfo(!showInfo);
    };

    return (
        <SafeAreaView style={stylesQurbani.container}>
            <View style={{width:'90%',padding:20,marginTop:20}}>
                <Text style={{fontsize:24,fontweight:'bold',fontFamily:'HindSiliguri_700Bold'}}>কোরবানীর ফযীলত</Text>
                <Text style={{fontSize:16,fontFamily:'HindSiliguri_700Bold'}}>কোরবানির জন্তুর শরীরে যত পশম থাকে, প্রত্যেকটা পশমের পরিবর্তে এক একটি নেকী পাওয়া যায়।
                    কোরবানির দিনে কোরবানি করাই সবচেয়ে বড় ইবাদত।</Text>
            </View>
            <View style={{width:'90%',padding:20,fontFamily:'HindSiliguri_700Bold'}}>

            <Text style={{fontsize:24,fontweight:'bold',fontFamily:'HindSiliguri_700Bold'}}>কাদের উপর কোরবানি দেয়া ওয়াজিব</Text>
            <Text style={{fontSize:16,fontFamily:'HindSiliguri_700Bold'}}>১০ই জিলহজ্বের ফজর থেকে ১২ই জিলহজ্বের সন্ধ্যা পর্যন্ত অর্থাৎ,কোরবানির দিনগুলোতে যার নিকট সদকায়ে ফিতর/ফিতরা ওয়াজিব হওয়া পরিমাণ অর্থ সম্পদ থাকে তার উপর কোরবানী করা ওয়াজিব।
                মুসাফিরের উপর (সফরের হালতে থাকলে)কোরবানী করা ওয়াজিব হয়না।
                কোরবানি ওয়াজিব না হলেও নফল কুরবানি করলে কোরবানীর ছওয়াব পাওয়া যাবে।
                কোরবানি শুধু নিজের পক্ষ থেকে ওয়াজিব হয়-সন্তানাদি,মাতা-পিতা ও স্ত্রীর পক্ষ থেকে ওয়াজিব হয় না, তবে তাদের পক্ষ থেকে করলে তা নফল কুরবানি হবে।
                যার উপর কোরবানি ওয়াজিব নয় সে কোরবানীর নিয়তে পশু ক্রয় করলে সেই পশু কোরবানী করা তার উপর ওয়াজিব হয়ে যায়।
                কোন মকসূদের জন্য কোরবানীর মান্নত করলে সেই মকসূদ পূর্ণ হলে তার উপর (গরীব হোক বা ধনী)কোরবানি করা ওয়াজিব হয়ে যায়।
                যার উপর কোরবানি ওয়াজিব সে কোরবানি না করলে কোরবানির দিনগুলো চলে যাওয়ার পর একটা বকরীর মূল্য সদকা করা ওয়াজিব।</Text>
            </View>
            <View style={{width:'90%',padding:20,fontFamily:'HindSiliguri_700Bold'}}>
            <Text style={{fontsize:24,fontweight:'bold',fontFamily:'HindSiliguri_700Bold'}}>কোকোন্‌ কোন্‌ জন্তু কোরবানী করা দুরস্ত...</Text>
                <Text style={{fontsize:16}}>বকরী,পাঠা,খাসী,ভেড়া,ভেড়ি,দুম্বা,গাভী, ষাঁড়,বলদ,মহিষ,উট এই কয় প্রকার গৃহপালিত জন্তু দ্বারা কোরবানী করা দুরস্ত।</Text>
        </View>
            <View style={{width:'90%',padding:20}}>
                <Text style={{fontsize:24,fontweight:'bold',fontFamily:'HindSiliguri_700Bold'}}>ককোরবানীর জন্তুর বয়স প্রসঙ্গে</Text>
                <Text style={{fontsize:16,fontFamily:'HindSiliguri_700Bold'}}>ববকরী,খাসী,ভেড়া,ভেড়ী,দুম্বা কম পক্ষে পূর্ণ এক বৎসর হতে হবে।তবে ভেড়া, ভেড়ী ও দুম্বার বয়স যদি কিছু কমও হয় কিন্তু এরূপ মোটা তাজা হয় যে,এক বৎসর বয়সীদের মধ্যে ছেড়ে দিলেও তাদের চেয়ে ছোট মনে হয় না, তাহলে তার দ্বারা কোরবানী দুরস্ত আছে;তবে অন্তত: ছয়মাস বয়স হতে হবে।বকরীর ক্ষেত্রে এরূপ ব্যতিক্রম নেই।বকরী কোন অবস্থায় এক বৎসরের কম বয়সের হতে পারবে না।
                    গরু ও মহিষের বয়স কম পক্ষে দুই বৎসর হতে হবে।
                    উট-এর বয়স কম পক্ষে পাঁচ বৎসর হতে হবে।</Text>
            </View>
            <View style={{width:'90%',padding:20}}>
                <Text style={{fontsize:24,fontweight:'bold',fontFamily:'HindSiliguri_700Bold'}}>কোরবানীর জন্তুর স্বাস্থ্যগত অবস্থা প্রসঙ্গে</Text>
                <Text style={{fontsize:16,fontFamily:'HindSiliguri_700Bold'}}>কোরবানীর পশু ভাল এবং হৃষ্ট-পুষ্ট হওয়া উত্তম|
                    যে প্রাণী লেংড়া অর্থাৎ যা তিন পায়ে চলতে পারে, এক পা মাটিতে রাখতে পারে না বা রাখতে পারলেও তার উপর ভর করতে পারে না - এরূপ পশু দ্বারা কোরবানী জায়েজ নয়।
                    যে পশুর একটিও দাঁত নেই তা দ্বারা কোরবানী হবে না। তবে দাঁত না থাকা সত্ত্বেও ঘাস খেতে সক্ষম হলে তা দ্বারা কোরবানী করা যাবে।
                    যে পশুর কান জন্ম থেকেই নেই তা দ্বারা কোরবানী হবে না। তবে কান ছোট হলে অসুবিধা নেই।
                    যে পশুর শিং মূল থেকে ভেঙ্গে যায় তা দ্বারা কোরবানী হবে না। তবে শিং ওঠেইনি বা কিছু পরিমাণ ভেঙ্গে গিয়েছে এরূপ পশুর কোরবানী করা যাবে।
                    যে পশুর উভয় চোখ অন্ধ বা একটি চোখ পূর্ণ অন্ধ বা একটি চোখের দৃষ্টি শক্তি এক তৃতীয়াংশের বেশী নষ্ট তা দ্বারা কোরবানী দুরস্ত নয়।
                    যে পশুর একটি কান বা লেজের এক তৃতীয়াংশের চেয়ে বেশী কেটে গিয়েছে তা দ্বারা কোরবানী হবে না।
                    অতিশয় কৃশকায় ও দুর্বল পশু যার এতটুকু শক্তি নেই যে, জবেহের স্থান পর্যন্ত হেটে যেতে পারে তা দ্বারা কোরবানী হবে না।
                    ভাল পশু ক্রয় করার পর এমন দোষ ত্রূটি দেখা দিয়েছে যার কারণে কোরবানী হবে না। এরূপ হলে এ জন্তুটি রেখে আর একটি ক্রয় করে কোরবানী করতে হবে। তবে ক্রেতা গরীব হলে সেটিই কোরবানী দিতে পারবে।
                    গর্ভবতী পশু কোরবানী করা জায়েজ। যদি পেটের বাচ্চা জীবিত পাওয়া যায় তবে সে বাচ্চাও জবেহ করে দিবে। তবে প্রসবের নিকটবর্তী হলে সেরূপ গর্ভবতী পশু কোরবানী দেয়া মাকরূহ।
                    বন্ধ্যা পশু কোরবানী করা জায়েজ।</Text>
            </View>
            <View style={{width:'90%',padding:20}}>
                <Text style={{fontsize:24,fontweight:'bold',fontFamily:'HindSiliguri_700Bold'}}>শরীকের মাসায়েল </Text>
                <Text style={{fontsize:16,fontFamily:'HindSiliguri_700Bold'}}>বকরী খাসী,পাঠা, ভেড়া, ভেড়ী ও দুম্বায় এক জনের বেশী শরীক হয়ে কুরবানি করা যায় না। এগুলো একটা একজনের নামেই কোরবানী হতে পারে।
                    একটা গরু, মহিষ ও উটে সর্বোচ্চ সাতজন শরীক হতে পারে। সাতজন হওয়া জরুরী নয়-দুইজন বা তিনজন বা চারজন বা পাঁচজন বা ছয়জন কোরবানী দিতে পারে,তবে কারও অংশ সাত ভাগের এক ভাগের চেয়ে কম হতে পারবে না।
                    যে পশুর একটিও দাঁত নেই তা দ্বারা কোরবানী হবে না। তবে দাঁত না থাকা সত্ত্বেও ঘাস খেতে সক্ষম হলে তা দ্বারা কোরবানী করা যাবে।
                    মৃতের নামেও কোরবানী হতে পারে।
                    রাসূলুল্লাহ সাল্লাল্লাহু আলাইহি ওয়াসাল্লাম, তার বিবিগণ বুজুর্গদের নামেও কোরবানী হতে পারে।
                    যে ব্যক্তি খাটি অন্তরে আল্লাহ্'র উদ্দেশ্যে কুরবানি করে না বরং গোশত খাওয়া বা লোক দেখানো ইত্যাদি নিয়তে কোরবানী করে, তাকে অংশীদার বানিয়ে কোন পশু কুরবানি করলে সকল অংশীদারের কোরবানী-ই নষ্ট হয়ে যায়।তাই শরীক নির্বাচনের সময় খুবই সতর্ক থাকা দরকার।
                    কোরবানীর পশু ক্রয় করার সময় শরীক রাখার এরাদা ছিল না,পরে শরীক গ্রহণ করতে চাইলে ক্রেতা গরীব হলে তা পারবে না, অন্যথায় পারবে।
                    যার সমস্ত উপার্জন বা অধিকাংশ উপার্জন হারাম, তাকে শরীক করে কোরবানী করলে অন্যান্য সকল শরীকের কোরবানী অশুদ্ধ হয়ে যাবে।</Text>
            </View>
            <View style={{width:'90%',padding:20}}>
                <Text style={{fontsize:24,fontweight:'bold',fontFamily:'HindSiliguri_700Bold'}}>কোরবানির পশু জবেহ করা প্রসঙ্গে</Text>
                <Text style={{fontsize:16,fontFamily:'HindSiliguri_700Bold'}}>বনিজের কোরবানির পশু নিজেই জবেহ করা উত্তম। নিজে জবেহ না করলে বা করতে না পারলে জবেহের সময় সামনে থাকা ভাল। মেয়ে-লোকের পর্দার ব্যাঘাত হওয়ার কারণে সামনে না থাকতে পারলে ক্ষতি নেই।
                    কোরবানির পশুকে মাটিতে শুইয়ে তার মুখ কেবলামুখী করে নিম্নের দোআ পাঠ করা উত্তম অতঃপর বলে জবেহ করবে। কেউ দোআ পড়তে না পারলে শুধু "বিসমিল্লাহি আল্লাহু আকবার বলে" জবেহ করলেও চলবে।
                    কোরবানি দাতা বা কোরবানি দাতাগণের নাম মুখে উচ্চারণ করা বা কাগজে লিখে পড়া জরুরী নয়।আল্লাহ পাক জানেন এটা কার কুরবানি। সে অনুযায়ী সে কুরবানি গৃহীত হবে।
                    কুরবানির পশু রাতের বেলায়ও জবেহ করা জায়েজ তবে ভাল নয়।
                    ঈদের নামাযের পূর্বে কুরবানি করা জায়েজ নয়। তবে যেখানে জুমুআ ও ঈদের নামায দুরস্ত নয় সেখানে সুবহে সাদেকের পর থেকেই কোরবানি করা দুরস্ত আছে।</Text>
            </View>
            <View style={{width:'90%',padding:20}}>
                <Text style={{fontsize:24,fontweight:'bold',fontFamily:'HindSiliguri_700Bold'}}>গোশত বণ্টনের তরীকা</Text>
                <Text style={{fontsize:16,fontFamily:'HindSiliguri_700Bold'}}>অংশীদারগণ সকলে একান্নভুক্ত হলে গোশত বণ্টনের প্রয়োজন নেই।অন্যথায় বণ্টন করতে হবে।
                    অংশীদারগণ গোশত অনুমান করে বণ্টন করবে না বরং বাটখারা দিয়ে ওজন করে বণ্টন করতে হবে।অন্যথায় ভাগের মধ্যে কমবেশ হয়ে গেলে গোনাহগার হতে হবে। অবশ্য কোন অংশীদার মাথা, পায়া ইত্যাদি বিশেষ কোন অংশ গ্রহণ করলে তার ভাগে গোশত কিছু কম হলেও তা দুরস্ত হবে।কিন্তু যে ভাগে গোশত বেশী সে ভাগে মাথা পায়া ইত্যাদি বিশেষ অংশ দেয়া যাবে না।
                    অংশীদারগণ সকলে যদি সম্পূর্ণ গোশত দান করে দিতে চায় বা সম্পূর্ণটা রান্না করে বিলাতে বা খাওয়াতে চায় তাহলে বণ্টনের প্রয়োজন নেই।</Text>
            </View>



        </SafeAreaView>
    );



};


const Stack = createNativeStackNavigator();

function App() {
    return (
        <NavigationContainer>
            {/*<Stack.Navigator initialRouteName="PrayerTimeScreen" >*/}
            <Stack.Navigator initialRouteName="QurbaniScreen" >
                

                <Stack.Screen name="QurbaniScreen" component={QurbaniScreen} options={{ navigationOptions: {
                        headerTitleStyle: {
                            fontFamily:HindSiliguri_500Medium
                        }
                    }, headerTitleAlign:'center', title: 'কোরবানী',headerStyle: {
                        backgroundColor: '#ffd602'},
                    headerTintColor: '#1e3a74'}} />

            </Stack.Navigator>
        </NavigationContainer>
    );
}

const marginbottom = Platform.select({
    ios: screenHeight * 0.05,
    android: screenHeight * 0.03,
});
const styles = StyleSheet.create({
        home_container: {
            // height:"100%",
            // width:"100%",
            //paddingTop: -5,
            // alignItems: "center",
            // backgroundColor:"#f1f1f1",
            flex: 1,
            // alignItems: 'center',
            // justifyContent: 'center'
        },
    picker: {
        width: 70,
        marginBottom:20,
        // marginTop: screenHeight < 800 ? screenHeight*0.1: screenHeight*0.06,
         // marginBottom: screenHeight*.05,
        alignSelf:"flex-end",
        // marginLeft:40,
        color:'#1e3a74',
        // color:'white'
    },
    bntext:{
        color:'#1e3a74',
        fontSize:12,
        // justifyContent:'space-around',

        alignSelf:'space-around',
        // fontWeight:'bold',
        fontFamily:'HindSiliguri_600SemiBold'
        //fontWeight:'bold'
    },
    entext:{
        color:'#1e3a74',
        fontSize:12,
        // justifyContent:'space-around',

        alignSelf:'space-around',
        fontWeight:'bold',
        fontFamily:'Acephimere'
        //fontWeight:'bold'
    },
        footerstyle:{
            backgroundColor: '#ffd602',
            height: "5%",
            paddingLeft:10,
            paddingTop:5,
            width:'100%'
        },
    header: {
        width: '100%',
        height: 120,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'lightgray',
        marginBottom:10
    },
    logo: {
        width: screenWidth * 0.7,
        height: screenHeight * 0.2,
         marginBottom: screenHeight*0.05,
        alignSelf:'center'
    },
        image: {
            // alignItems: 'center',
            // justifyContent: 'center',
            // marginTop:margintop,
            // marginTop:Platform.select({
            //     ios: screenHeight < 700 ? screenHeight * 1:screenHeight * 1,
            //     android: screenHeight * 0.03,
            // }),
            // marginBottom:marginbottom,
            // marginTop:screenHeight < 700 ? screenHeight*0.2: screenHeight*0.003,
            // flex: 1,
            // justifyContent: "center",
            // backgroundColor:'#f1f1f1'
        },
        text: {
            color: '#1b8b96',
            fontSize: 12,
            //fontWeight:'bold'
        },
        contents: {
            flexDirection: "column",
            height: 120,
            width: 120,
            borderRadius: 120 / 2,
            alignItems: "center"
        },
        viewstyle: {
             backgroundColor: '#f1f1f1',
            height: "100%",
            // alignItems:"center",
            //paddingTop:30
            // width:"100%"

        },
        im: {
            width: 40,
            height: 40

        },
        divstyle: {
            // height: "18%",
            // alignItems: 'center',
            // flexDirection: 'row',
            alignSelf: 'center',
            // width:"100%",
            // paddingTop:10
            width: '100%',
            // height:800,
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            marginTop: 20,
            paddingHorizontal: 20,

        },
        container: {
            flex: 1
        },
        image_background: {
            flex: 1,
            justifyContent: "center"
        },
    divstyle_Dua: {
        height: 100,
        alignItems: 'center',
        flexDirection: 'row',
        alignSelf: 'flex-start'

    },
    viewstyle_Dua:{
        // backgroundColor: '#f1f1f1',
        height: "100%",
        padding: 5,
        alignSelf:'center',
        flex:1
    }
    ,

    text_Dua:{
        color:'#1e3a74',
        fontSize:20,
        fontWeight:"bold"
    },
    contentstyle_Dua:{
        paddingRight:20,
        paddingTop:20,
        paddingLeft:20,
        paddingBottom:20
    },
    divstyle_Roja: {
        height: 100,
        alignItems: 'center',
        flexDirection: 'row',
        alignSelf: 'flex-start'

    },
    viewstyle_Roja:{
        // backgroundColor: '#f1f1f1',
        height: 700,
        padding: 5,
        alignSelf:'center'
    }
    ,

    text_Roja:{
        color:'#1e3a74',
        fontSize:20,
        fontWeight:"bold"
    },
    contentstyle_Roja:{
        paddingRight:20,
        paddingTop:20,
        paddingLeft:50,
        paddingBottom:50
    },
    divstyle_quran: {
        height: 150,
        alignItems: 'center',
        flexDirection: 'row',
        alignSelf: 'flex-start',
        paddingTop:10,
        paddingBottom:50,
        paddingLeft:20,
        paddingRight:15
    },
    viewstyle_quran:{
        backgroundColor: 'f1f1f1',
        height: 400,
        width:"100%",
        // padding: 5
    }
    ,

    text_quran:{
        color:'#1e3a74',
        fontSize:20,
        fontWeight:"bold"
    },
    contentstyle:{
        paddingRight:50,
        paddingTop:10,
        paddingLeft:30,
        paddingBottom:20
    },
    contentstyleDua:{
        paddingRight:50,
        paddingTop:10,
        paddingLeft:30,
        paddingBottom:10
    },
    divstyle_quranScreen: {
        height: "18%",
        alignItems: 'center',
        flexDirection: 'row',
        alignSelf: 'flex-start'

    },
    viewstyle_quranScreen:{
        // backgroundColor: '#f1f1f1',
        height: "100%",
        width:"100%",
        padding: 5
    }
    ,

    // text_quranScreen:{
    //     color:'#1e3a74',
    //     fontSize:20,
    //     fontWeight:"bold"
    // },
    contentstyle_quranScreen:{
        paddingRight:20,
        paddingTop:20,
        paddingLeft:50,
        paddingBottom:50
    },
    footerstyle_quranScreen:{
        backgroundColor: '#1e3a74',
        height: "6%",
        paddingLeft:10,
        paddingTop:10
    },
    divstyle_KitabScreen: {
        justifyContent:"center",
        flex:.5,
        //   alignItems: 'flex-start',
        flexDirection: 'row',
        alignSelf: 'center',
        paddingLeft:30,
        paddingRight:30

    },
    viewstyle_KitabScreen:{
        height:"100%",
        width:"100%",
        // alignSelf:"center",
        alignSelf:"center",
        paddingBottom:8,
        backgroundColor:"#f1f1f1"
    }
    ,

    text_KitabScreen:{
        color:'#1e3a74',
        fontSize:20,
        fontWeight:"bold"
    },
    contentstyle_KitabScreen:{
        paddingRight:20,
        paddingTop:20,
        paddingLeft:20,
        paddingBottom:20
    },
        divstyle_book_bukhari: {
            justifyContent:"center",
            flex:.5,
            //   alignItems: 'flex-start',
            flexDirection: 'row',
            //  alignSelf: 'center',
            paddingLeft:30,
            paddingRight:30,
            //backgroundColor:"#185165"

        },
        viewstyle_book_bukhari:{
            height:"100%",
            width:"100%",
            // alignItems:"flex-start",
            alignSelf:"center",
            paddingBottom:8,
            backgroundColor:"#f1f1f1",
            // marginRight:10
        }
        ,

        text_book_bukhari:{
            color:'#1e3a74',
            fontSize:20,
            fontWeight:"bold"
        },
        contentstyle_book_bukhari:{
            paddingRight:20,
            paddingTop:5,
            paddingLeft:20,
            paddingBottom:20
        },
    QuranShikkha_divstyle: {
        height: 100,
        alignItems: 'center',
        flexDirection: 'row',
        alignSelf: 'flex-start'

    },
    QuranShikkha_viewstyle:{
        backgroundColor: '#f1f1f1',
        height: 700,
        padding: 5
    },
    QuranSurahView:{
        alignItems:'center', paddingTop:20
    },
    QuranPress:{width:'90%', height:50},
    QuranCard:{width:'100%', height:60,backgroundColor:'#f1f1f1'},
    QuranCardView:{flexDirection:'row',padding:6,alignSelf:'flex-start'},
    QuranCircle1:{width:50,height:50,backgroundColor:'#1e3a74',borderRadius:50/2},
    QuranCardView2:{alignItems:'center',paddingTop:2},
    QuranCircle2:{width:46,height:46,backgroundColor:'#ffd602',borderRadius:46/2,borderColor:'#f1f1f1',borderWidth:2,alignSelf:'center'},
    QuranCircleText:{textAlign:'center', color:'white',padding:2,paddingTop:10,fontSize:16,fontFamily:'HindSiliguri_700Bold'},
    QuranSuraView:{width:'100%'},
    QuranSuraName:{color:'#1e3a74',paddingLeft:20,paddingTop:5,fontSize:15,fontFamily:'HindSiliguri_500Medium',flexDirection:'center'},
    QuranAyatName:{color:'#1e3a74',paddingLeft:20,paddingTop:2,fontWeight:'500',fontSize:12,fontFamily:'HindSiliguri_300Light',flexDirection:'center'},
    QuranImage:{padding:10,width:'5%'},
    QuranImageStyle:{alignItems: 'flex-end',height: 20, width:20},
    bntextWaqt:{
        color:'#1e3a74',
        fontSize: 14,
        marginTop: 0,
        marginBottom:20,
         alignSelf:'center',
        fontFamily:'HindSiliguri_600SemiBold'
    },
    entextWaqt:{
        color:'#1e3a74',
        fontSize: 14,
        marginTop: 0,
        marginBottom:20,
         alignSelf:'center',
        fontWeight:'bold',
        fontFamily:'Acephimere'
    },

    }
)

export default App;
const stylesQurbani = StyleSheet.create({
    container: {
        flex: 1,
        // justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f1f1f1',
    },
    buttonText: {
        fontSize: 20,
        color: 'blue',
    },
    infoContainer: {
        padding: 20,
    },
    infoText: {
        fontSize: 16,
    }
}   );
